<template>
  <div class="student" ref="student">
    <keep-alive>
      <list-template
          :loading="loading"
          :total="total"
          :current-page="page"
          :table-data="tableData"
          :table-config="tableConfig"
          :search-config="searchConfig"
          @onSearch="onSearch"
          @onReset="search = null"
          @onChangePage="changeCurrentPage"
          @onHandle="tableHandle"
      ></list-template>
    </keep-alive>
  </div>
</template>

<script>
import ListTemplate from '@/components/pages/List'
import {mapState} from 'vuex'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading:true,
      // 表格搜索配置
      searchConfig: [
        {
          prop: 'student_name',
          placeholder: '搜索学生姓名'
        },
        {
          prop: 'class_name',
          placeholder: '搜索行政班级名称'
        },
        {
          prop: 'school_id',
          placeholder: '搜索校区',
          tag: 'select',
          options: []
        }
      ],
      // 表格配置
      tableConfig: [
        {
          prop: 'student_name',
          label: '学生姓名'
        },
        {
          prop: 'grade_name',
          label: '年级'
        },
        {
          prop: 'class_name',
          label: '行政班班级'
        },
        {
          prop: 'school_name',
          label: '校区'
        },
        {
          prop: 'period_name',
          label: '课表名称'
        },
        {
          prop: '',
          label: '操作',
          width: 120,
          handle: true,
          buttons: () => [{ type:"viewSchedule", text:"查看课程表" }]
        }
      ],
      search: null,
      // 总数据量
      total: 0,
      // 表格中的数据
      tableData: []
    }
  },
  computed: {
    ...mapState(['page'])
  },
  components: {
    ListTemplate
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.getOption()
  },
  methods: {
    getOption() {
      const obj = this.$store.getters.userInfo.school_arr
      let options = []
      Object.keys(obj).forEach(key => {
        options.push({ label: obj[key], value: key })
      })
      this.searchConfig[2].options = options
    },
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    // 获取数据
    getData() {
      let {search, page} = this
      this.loading = true;
      this.$_axios.get('course/student-plan', {params: {...search, page}}).then(res => {
        this.tableData = res.data.data.list
        let {total} = this.$tools.getPaginationInfo(res.headers)
        this.total = total
      }).finally(()=>this.loading = false)
    },
    // 搜索功能
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData()
    },
    // 表格后面操作被点击
    tableHandle(row, text, index) {
      this.$router.push({path: './detail', query: {id: row.id}})
    }
  }
}
</script>

<style scoped lang="scss">
</style>
